<template>
  <l-map class="map" ref="map" :options="mapOptions" :zoom="zoom" :center="center" :maxBounds="maxBounds" :minZoom="minZoom" :maxZoom="maxZoom" @leaflet:load="$emit('loaded')" @ready="$emit('ready', { bounds: $event.getBounds(), zoom: $event.getZoom() })" @update:zoom="$emit('update:zoom', $event)" @update:bounds="$emit('update:bounds', $event)">
    <l-tile-layer :url="url" :attribution="attribution" :tileSize="tileSize" :minZoom="minZoom" :maxZoom="maxZoom" :options="tileOptions"></l-tile-layer>

    <l-control-attribution position="bottomright" :prefix="status"></l-control-attribution>
    <l-control-zoom position="topright"></l-control-zoom>

    <slot />
  </l-map>
</template>

<script>
import { latLngBounds } from 'leaflet'
import { LMap, LTileLayer, LLayerGroup, LMarker, LControl, LControlAttribution, LControlZoom } from 'vue2-leaflet'
import terminator from '@joergdietrich/leaflet.terminator'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'

import { locationForUserTimeZone } from 'lib/timezone'

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
})

const locationFromParams = () => {
  if (params.lat && params.lon) {
    return [params.lat, params.lon]
  }
}

export default {
  props: {
    zoom: {
      type: Number,
      default: () => Number(params.zoom) || 5
    },

    center: {
      type: Array,
      default: () => locationFromParams() || locationForUserTimeZone()
    },

    status: {
      type: String,
      default: ""
    },

    options: {
      type: Object
    }
  },

  mounted () {
    this.terminator = terminator({ fillOpacity: 0.25 }).addTo(this.$refs.map.mapObject)
    this.interval = setInterval(() => this.terminator.setTime(), 60000)
  },

  destroyed () {
    clearInterval(this.interval)
  },

  data () {
    return {
      url: `https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${process.env.MAPBOX_TOKEN}`,
      attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      tileSize: 512,
      minZoom: 3,
      maxZoom: 18,
      mapOptions: {
        attributionControl: false,
        zoomControl: false
      },
      tileOptions: {
        id: 'mapbox/streets-v11',
        zoomOffset: -1
      },

      originalCenter: null,
      originalZoom: null,

      ...this.options
    }
  },

  methods: {
    flyTo (latlng, zoom, options) {
      this.mapObject.flyTo(latlng, zoom, options)
    },

    zoomOut (zoom, options) {
      if (this.mapObject.getZoom() > zoom) {
        this.mapObject.setZoom(zoom, options)
      }
    },

    createPane (name) {
      this.mapObject.createPane(name)
    },

    savePosition () {
      this.originalCenter = this.mapObject.getCenter()
      this.originalZoom = this.mapObject.getZoom()
    },

    restorePosition () {
      if (this.originalCenter && this.originalZoom) {
        this.flyTo(this.originalCenter, this.originalZoom, { duration: 0.1 })

        this.originalCenter = null
        this.originalZoom = null
      }
    },

    fitBounds (latlngs) {
      this.mapObject.fitBounds(latlngs)
    },

    getCenter () {
      return this.mapObject.getCenter()
    },

    logPosition () {
      console.log(this.getCenter(), this.mapObject.getZoom())
    }
  },

  computed: {
    mapObject () {
      return this.$refs.map.mapObject
    },

    maxBounds () {
      return latLngBounds([[-90, -180], [90, 180]])
    }
  },

  components: {
    LMap,
    LTileLayer,
    LLayerGroup,
    LMarker,
    LControl,
    LControlAttribution,
    LControlZoom,
    'v-marker-cluster': Vue2LeafletMarkerCluster
  }
}
</script>
