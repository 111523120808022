<template>
  <div ref="waveform" class="waveform w-100">
    <div ref="spectrogram" class="waveform-spectrogram" @click="play"></div>
  </div>
</template>

<script>
import WaveSurfer from 'wavesurfer.js'
import SpectrogramPlugin from 'wavesurfer.js/dist/plugins/spectrogram'
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions'

import Color from 'color'
import colormap from 'colormap'

import { calculateGain } from 'lib/audio'

import { mapState } from 'vuex'

const COLORS = colormap({
  colormap: 'jet',
  nshades: 256,
  format: 'float'
})

export default {
  props: {
    url: {
      type: String,
      required: true
    },

    startTime: {
      type: Number,
      default: 0
    },

    endTime: {
      type: Number,
      required: false
    },

    height: {
      type: Number,
      default: 32
    },

    regions: {
      type: Array,
      required: false
    },

    sampleRate: {
      type: Number,
      default: 48000
    }
  },

  data () {
    return {
      playing: false,
      currentGain: 1,
      regionsPlugin: RegionsPlugin.create()
    }
  },

  mounted () {
    this.wavesurfer = WaveSurfer.create({
      container: this.$refs.waveform,
      responsive: true,
      height: this.height,
      sampleRate: this.sampleRate,
      normalize: true,
      backend: 'WebAudio'
    })

    this.wavesurfer.registerPlugin(this.regionsPlugin)

    this.wavesurfer.on('ready', () => {
      this.$emit('loaded')

      if (this.regions.length) {
        this.regions.forEach(region => {
          this.regionsPlugin.addRegion({
            start: region.start,
            end: region.end,
            color: Color(region.color).alpha(0.2),
            loop: false,
            drag: false,
            resize: false
          })
        })

        this.wavesurfer.setTime(this.regions[0].start)
      } else if (typeof this.startTime !== 'undefined') {
        const startTime = Math.max(0, this.startTime)
        const endTime = this.endTime

        this.regionsPlugin.addRegion({
          start: startTime,
          end: endTime,
          color: 'rgba(66, 148, 136, 0.2)',
          loop: false,
          drag: false,
          resize: false
        })

        this.wavesurfer.setTime(this.startTime)
      }
    })

    this.wavesurfer.on('error', (message) => {
      console.error(`Soundscape failed to load: ${message}`)
    })

    this.wavesurfer.on('pause', () => {
      this.playing = false
      this.$emit('pause')
    })

    this.wavesurfer.on('play', () => {
      this.playing = true
      this.$emit('play')
    })

    this.wavesurfer.on('ready', () => {
      this.updateGain()
      this.initializeSpectrogram()
    })

    this.wavesurfer.load(this.url)
  },

  destroyed () {
    this.wavesurfer.destroy()
  },

  computed: {
    ...mapState(['automaticGain']),

    fftSamples () {
      if (window.devicePixelRatio == 1) {
        return 512
      } else {
        return 1024
      }
    },

    gainDB () {
      return 20 + 1.5 * Math.log(this.currentGain)
    },

    rangeDB () {
      return 80 + 6.25 * Math.log(this.currentGain)
    }
  },

  methods: {
    play () {
      if (this.wavesurfer.isPlaying()) {
        this.wavesurfer.pause()
      } else if (this.regions) {
        const region = this.regionsPlugin.getRegions()[0]
        const currentTime = this.wavesurfer.getCurrentTime()

        if (region && currentTime >= region.start && currentTime <= region.end) {
          region.play()
        } else {
          this.wavesurfer.play()
        }
      } else {
        this.wavesurfer.play()
      }
    },

    setGain (gain) {
      this.wavesurfer.media.gainNode.gain.value = gain
      this.currentGain = gain
    },

    updateGain () {
      if (this.automaticGain) {
        const buffer = this.wavesurfer.decodedData
        const gain = calculateGain(buffer.getChannelData(0), buffer.sampleRate)
        this.setGain(gain)
      } else {
        this.setGain(1)
      }
    },

    initializeSpectrogram () {
      if (this.spectrogramPlugin) {
        this.spectrogramPlugin.destroy()
      }

      this.spectrogramPlugin = this.wavesurfer.registerPlugin(
        SpectrogramPlugin.create({
          container: this.$refs.spectrogram,
          fftSamples: this.fftSamples,
          scale: 'linear',
          colorMap: COLORS,
          height: 512,
          frequencyMax: this.sampleRate / 4,
          noverlap: 0,

          gainDB: this.gainDB,
          rangeDB: this.rangeDB
        })
      )

      this.wavesurfer.emit('redraw')
    }
  },

  watch: {
    url () {
      this.regionsPlugin.clearRegions()
      this.wavesurfer.load(this.url)
    },

    automaticGain () {
      this.updateGain()
      this.initializeSpectrogram()
    }
  }
}
</script>
