<template>
  <div class="navbar navbar-expand-xxl flex-fill d-flex align-items-stretch p-0" style="min-height: 0">
    <MountingPortal mountTo="#header-slot">
      <SearchBar @selectStation="selectStation($event)" @selectSpecies="selectSpecies($event)" @clear="deselectStation()" />
    </MountingPortal>

    <MountingPortal mountTo="#toggler-slot">
      <Toggler icon="layer-group" :active="topbarActive" @toggle="toggleTopbar" aria-controls="topbar" aria-label="Toggle layers panel" class="d-xxl-none" />
    </MountingPortal>

    <MapWindowPane>
      <TopBirdWeatherSpeciesWindow v-if="windows.birdweatherTopSpecies" :selectedSpecies="selectedSpecies" :queryVariables="queryVariables" @select="selectSpecies" @loading="(loading) => layers.birdweather.loading = loading" @hide="windows.birdweatherTopSpecies = false" :zIndex="zIndex('topSpecies')" @bringToFront="bringToFront('topSpecies')" />
      <TopBirdNetSpeciesWindow v-if="windows.birdnetTopSpecies" :selectedSpecies="selectedSpecies" :queryVariables="queryVariables" @select="selectSpecies" @loading="(loading) => layers.birdnet.loading = loading" @hide="windows.birdnetTopSpecies = false" :zIndex="zIndex('topSpecies')" @bringToFront="bringToFront('topSpecies')" />

      <SpeciesWindow v-if="selectedSpecies && windows.speciesInfo" :species="selectedSpecies" :queryVariables="queryVariables" :period="period" @hide="windows.speciesInfo = false" :zIndex="zIndex('speciesInfo')" @bringToFront="bringToFront('speciesInfo')" />
    </MapWindowPane>

    <MapWindow v-if="selectedStation && selectedStation.videoUrl" class="position-absolute bottom center" :title="`${selectedStation.name} Stream`" closeButton="minimize" :zIndex="zIndex('stream')" @bringToFront="bringToFront('stream')">
      <youtube :video-id="selectedStationVideoId" :player-vars="{ autoplay: 1, modestbranding: 1, playsinline: 1, controls: 0 }" :width="432" :height="243" ref="youtube" class="d-block w-100 rounded-1" />
    </MapWindow>

    <MapWindow v-if="selectedStation && selectedStation.audioUrl" class="position-absolute bottom center" :title="`${selectedStation.name} Stream`" closeButton="minimize" :zIndex="zIndex('stream')" @bringToFront="bringToFront('stream')">
      <AudioStream :source="selectedStation.audioUrl" />
    </MapWindow>

    <MapWindowPane class="center" v-if="selectedStation" :zIndex="zIndex('station')" @bringToFront="bringToFront('station')">
      <MapWindow class="p-1" :class="{ 'puc-map-window': selectedStation.type === 'puc' }" :header="false">
        <StationInfo :station="selectedStation" :period="period" :autoload="true" class="px-2 py-1">
          <button type="button" class="btn-close ms-3" aria-label="Close" @click="deselectStation"></button>
        </StationInfo>
      </MapWindow>

      <a v-if="!selectedStationIsCentered" @click.prevent="centerSelectedStation" href="#" class="map-window text-center py-1 px-2 align-self-center x-small">Recenter</a>
    </MapWindowPane>

    <MapWindow :header="false" class="station-stats-window position-absolute bottom right py-2 px-3" v-if="layers.birdweather.enabled || layers.birdnet.enabled || (selectedSpecies && layers.ebird.enabled)">
      <StationStats :counts="counts" :loading="$apollo.queries.counts.loading" :layers="layers.birdweather.types" :stations="layers.birdweather.enabled" :birdnet="layers.birdnet.enabled && !selectedStation" :ebird="selectedSpecies && layers.ebird.enabled" :ebirdLoading="layers.ebird.loading" :ebirdCount="layers.ebird.count" @toggleLayer="toggleStationType" />
    </MapWindow>

    <div class="flex-fill d-flex flex-column position-relative">
      <div class="map-overlay-container">
        <NavBar ref="topbar" id="topbar" class="topbar app-top flex-shrink-0" :active.sync="topbarActive">
          <div class="topbar-section topbar-section-search">
            <SearchBar @selectStation="selectStation($event)" @selectSpecies="selectSpecies($event)" @clear="deselectStation()" />
          </div>

          <div class="topbar-group me-auto">
            <div class="topbar-section topbar-section-data-layers">
              <label class="topbar-section-label">Data Layers</label>

              <DataLayer name="BirdWeather" v-model="layers.birdweather.enabled" :loading="layers.birdweather.loading" :caption="visibleStationsDescription">
                <button class="btn btn-secondary btn-sm" :class="{ active: windows.birdweatherTopSpecies }" @click="toggleTopSpeciesWindow('birdweather')">
                  <font-awesome-icon icon="sort-numeric-down" />
                </button>
              </DataLayer>

              <DataLayer name="BirdNET" v-model="layers.birdnet.enabled" :loading="layers.birdnet.loading" :caption="birdNETLayerDescription" :enabled="!!selectedSpecies">
                <button class="btn btn-secondary btn-sm" :class="{ active: windows.birdnetTopSpecies }" @click="toggleTopSpeciesWindow('birdnet')">
                  <font-awesome-icon icon="sort-numeric-down" />
                </button>
              </DataLayer>

              <DataLayer name="eBird" v-model="layers.ebird.enabled" :loading="layers.ebird.loading" :caption="eBirdLayerDescription" :enabled="!!selectedSpecies" />

              <DataLayer name="Range" v-model="layers.range.enabled" :loading="layers.range.loading" :caption="rangeLayerDescription" :enabled="!!selectedSpecies" />

              <DataLayer name="Weather" v-model="layers.weather.enabled" :caption="weatherLayerDescription">
                <div class="dropdown">
                  <button class="btn btn-secondary btn-sm" data-bs-toggle="dropdown">
                    <font-awesome-icon icon="cloud-sun-rain" />
                  </button>

                  <ul class="dropdown-menu dropdown-menu-end arrow">
                    <li v-for="(name, id) in weatherLayers" :key="id">
                      <a href="#" class="dropdown-item" :class="{ active: layers.weather.enabled && layers.weather.layer === id }" @click.prevent="enableWeatherLayer(id)">{{ name }}</a>
                    </li>
                  </ul>
                </div>
              </DataLayer>

              <!--<DataLayer name="Solar Eclipse" v-model="layers.eclipse.enabled" :loading="layers.eclipse.loading" :caption="solarEclipseLayerDescription">
                <a href="https://www.birdweather.com/2024-solar-eclipse" class="btn btn-secondary btn-sm" target="_blank">
                  <font-awesome-icon icon="circle-info" />
                </a>
              </DataLayer>-->
            </div>
          </div>

          <div class="topbar-group">
            <div v-if="selectedSpecies" class="topbar-section topbar-section-selected-species" @click="toggleSpeciesInfoWindow">
              <label class="topbar-section-label d-block d-lg-none">Selected Species</label>

              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <SpeciesThumbnail :species="selectedSpecies" class="small" />
                </div>

                <div class="flex-grow-1 mx-2">
                  <p class="m-0">{{ selectedSpecies.commonName }}</p>
                  <p class="m-0 small text-muted">{{ selectedSpecies.scientificName }}</p>
                </div>

                <div class="flex-shrink-0">
                  <button class="btn btn-close btn-deselect-species" @click="selectSpecies(null)" />
                </div>
              </div>
            </div>

            <div class="topbar-section pe-1">
              <label class="topbar-section-label">{{ periodDescription(period) }}</label>

              <PeriodPicker v-if="enablePeriodSelection" v-model="period" :opensDirection="periodPickerOpensDirection">
                <button class="btn btn-primary btn-sm">
                  <font-awesome-icon icon="calendar-alt" />
                </button>
              </PeriodPicker>
            </div>

            <div class="topbar-section ps-xxl-1">
              <label class="topbar-section-label d-xxl-none">Settings</label>
              <Settings />
            </div>
          </div>
        </NavBar>

        <div class="flex-fill position-relative">
          <div class="selected-species-frame" :class="{ active: !!selectedSpecies }" :style="{ borderColor: frameColor }"></div>
        </div>
      </div>

      <Map ref="map" class="flex-fill" :status="status" :center="center" :zoom="initialStation ? 13 : undefined" @loaded="mapLoaded" @ready="mapReady" @update:bounds="updateBoundsDebounced" @update:zoom="updateZoomDebounced">
        <WeatherMapLayer v-if="layers.weather.enabled" :layer="layers.weather.layer" />
        <BirdWeatherMapLayer ref="birdweatherLayer" v-if="layers.birdweather.enabled" v-model="layers.birdweather" :station="selectedStation" :species="selectedSpecies" :period="period" :queryVariables="queryVariables" :zoom="zoom" :bounds="bounds" :focusArea="stationFocusArea" @select:detection="selectDetection" @select:station="selectStation" />
        <BirdNetMapLayer v-if="selectedSpecies && layers.birdnet.enabled" pane="birdnet" v-model="layers.birdnet" :species="selectedSpecies" :queryVariables="queryVariables" :markerRadius="sightingMarkerRadius" :zoom="zoom" />
        <EbirdMapLayer v-if="selectedSpecies && layers.ebird.enabled" pane="ebird" v-model="layers.ebird" :species="selectedSpecies" :queryVariables="queryVariables" :markerRadius="sightingMarkerRadius" :zoom="zoom" />
        <RangeMapLayer v-if="selectedSpecies && layers.range.enabled" v-model="layers.range" :species="selectedSpecies" />
        <!--<SolarEclipseMapLayer ref="eclipse" v-if="layers.eclipse.enabled" v-model="layers.eclipse" :now="currentTime" />-->

        <ImportStatus />
      </Map>
    </div>

    <DetectionModal v-if="activeDetection" ref="detection" :detection="activeDetectionWithStation" :showNavigator="false" @hide="activeDetection = null" />
    <SoundscapeModal v-else-if="activeSoundscape" ref="soundscape" :soundscape="activeSoundscape" :detection="activeDetectionWithStation" :showNavigator="false" @hide="activeSoundscape = null;" />
  </div>
</template>

<script>
import AudioStream from 'components/AudioStream'
import BirdNetMapLayer from 'components/layers/BirdNetMapLayer'
import BirdWeatherMapLayer from 'components/layers/BirdWeatherMapLayer'
import EbirdMapLayer from 'components/layers/EbirdMapLayer'
import DataLayer from 'components/DataLayer'
import DetectionModal from 'components/DetectionModal'
import DetectionNotifications from 'components/DetectionNotifications'
import ImportStatus from 'components/ImportStatus'
import Map from 'components/Map'
import MapLayer from 'components/layers/MapLayer'
import MapWindow from 'components/MapWindow'
import MapWindowPane from 'components/MapWindowPane'
import NavBar from 'components/NavBar'
import NavItem from 'components/NavItem'
import PeriodPicker from 'components/PeriodPicker'
import RangeMapLayer from 'components/layers/RangeMapLayer'
import SearchBar from 'components/SearchBar'
import Settings from 'components/Settings'
import SoundscapeModal from 'components/SoundscapeModal'
import SpeciesMapLayer from 'components/layers/SpeciesMapLayer'
// import SolarEclipseMapLayer from 'components/layers/SolarEclipseMapLayer'
import StationInfo from 'components/StationInfo'
import StationStats from 'components/StationStats'
import SpeciesThumbnail from 'components/SpeciesThumbnail'
import SpeciesWindow from 'components/SpeciesWindow'
import Toggler from 'components/Toggler'
import TopBirdNetSpeciesWindow from 'components/TopBirdNetSpeciesWindow'
import TopBirdWeatherSpeciesWindow from 'components/TopBirdWeatherSpeciesWindow'
import WeatherMapLayer from 'components/layers/WeatherMapLayer'

import { periodDescription } from 'lib/period'
import { formatCount } from 'lib/formatting'

import CountsQuery from 'queries/Counts.graphql'
import CountsMinimalQuery from 'queries/CountsMinimal.graphql'

import { debounce } from 'lodash'

import { mapState } from 'vuex'

const WEATHER_LAYERS = {
  precipitation_new: 'Precipitation',
  temp_new: 'Temperature',
  clouds_new: 'Clouds',
  pressure_new: 'Sea level pressure',
  wind_new: 'Wind speed'
}

export default {
  props: {
    center: {
      type: Array,
      required: false
    },

    initialStation: {
      type: Object,
      required: false
    },

    selectedDetection: {
      type: Object,
      required: false
    },

    selectedSoundscape: {
      type: Object,
      required: false
    },

    showCounts: {
      type: Boolean,
      default: false
    },

    showTopSpecies: {
      type: Boolean,
      default: false
    },

    enablePeriodSelection: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      status: '',

      currentTime: new Date(),

      period: { count: 24, unit: 'hour' },

      counts: {},

      selectedStation: this.initialStation,
      selectedStationIsCentered: true,
      selectedSpecies: null,

      activeDetection: null,
      activeSoundscape: null,

      bounds: null,
      zoom: null,

      frameColor: null,

      topbarActive: false,

      layers: {
        birdweather: {
          enabled: true,
          loading: false,

          types: {
            birdnetpi: true,
            puc: true,
            stream_youtube: true,
            stream_audio: true,
            app: true
          }
        },

        birdnet: {
          enabled: false,
          loading: false,
          count: null
        },

        ebird: {
          enabled: false,
          loading: false,
          count: null
        },

        range: {
          enabled: false,
          loading: false,
          hasData: false
        },

        weather: {
          enabled: false,
          layer: 'clouds_new'
        },

        eclipse: {
          enabled: false,
          loading: false
        }
      },

      windows: {
        birdweatherTopSpecies: this.showTopSpecies ? (window.outerWidth >= 992) : false,
        birdnetTopSpecies: false,
        speciesInfo: false
      },

      stackingOrder: [
        'topSpecies',
        'speciesInfo',
        'stream',
        'station'
      ]
    }
  },

  created () {
    this.updateBoundsDebounced = debounce(this.updateBounds, 500)
    this.updateZoomDebounced = debounce(this.updateZoom, 500)

    window.addEventListener('popstate', () => this.navigate(false))

    setInterval(() => this.currentTime = new Date(), 1000)
  },

  mounted () {
    if (this.selectedDetection) {
      this.selectDetection(this.selectedDetection)
    } else if (this.selectedSoundscape) {
      this.selectSoundscape(this.selectedSoundscape)
    }
  },

  methods: {
    periodDescription,

    logPosition () {
      this.$refs.map.logPosition()
    },

    mapLoaded () {
      this.$refs.map.createPane('species')

      this.$nextTick(() => {
        this.$refs.birdweatherLayer.setupPanes(this.$refs.map)
      })
    },

    mapReady ({ bounds, zoom }) {
      this.bounds = bounds
      this.zoom = zoom
    },

    updateBounds (bounds) {
      this.bounds = bounds
    },

    updateZoom (zoom) {
      this.zoom = zoom
    },

    normalizeBounds (bounds) {
      if (this.bounds) {
        const ne = this.convertPoint(this.bounds.getNorthEast())
        const sw = this.convertPoint(this.bounds.getSouthWest())

        if (Math.abs(ne.lon - sw.lon) > 360) {
          sw.lon = -180
          ne.lon = 180
        }

        if (Math.abs(ne.lat - sw.lat) > 180) {
          sw.lat = -90
          ne.lat = 90
        }

        return { ne: ne, sw: sw }
      } else {
        return {}
      }
    },

    convertPoint (point) {
      return {
        lat: point.lat,
        lon: point.lng
      }
    },

    selectSpecies (species) {
      this.selectedSpecies = species
      this.windows.speciesInfo = true
      this.bringToFront('speciesInfo')
    },

    focusStation (station) {
      this.selectedStation = station

      this.$refs.map.savePosition()
      this.centerSelectedStation()
    },

    centerSelectedStation () {
      const coords = this.stationCoords(this.selectedStation)
      this.selectedStationIsCentered = true
      this.$refs.map.flyTo(coords, Math.max(this.zoom, 13), { duration: 0.1 })
    },

    selectStation (station, updatePath) {
      if (station) {
        this.focusStation(station)
        this.hideTopBar()

        document.title = [station.name, this.pageTitleBase].join(' :: ')

        if (updatePath !== false) {
          window.history.pushState(null, null, `/stations/${this.selectedStation.id}`)
        }
      } else {
        this.deselectStation()
      }
    },

    selectStationById (id, updatePath) {
      if (!this.$refs.birdweatherLayer) return

      const station = this.$refs.birdweatherLayer.findStationById(id)

      if (station) {
        this.selectStation(station, updatePath)
      }
    },

    deselectStation (updatePath) {
      this.selectedStation = null
      this.$refs.map.restorePosition()

      document.title = this.pageTitleBase

      if (updatePath !== false) {
        window.history.pushState(null, null, '/')
      }
    },

    selectDetection (detection) {
      this.activeDetection = detection

      this.focusStation(detection.station)

      this.$nextTick(() => this.$refs.detection.show())

      // this.selectStation(detection.station)
      // this.selectSpecies(detection.species)
    },

    selectSoundscape (soundscape) {
      this.activeSoundscape = soundscape

      this.focusStation(soundscape.station)

      this.$nextTick(() => this.$refs.soundscape.show())
    },

    stationCoords (station) {
      return [station.coords.lat, station.coords.lon]
    },

    toggleSpeciesInfoWindow () {
      this.windows.speciesInfo = !this.windows.speciesInfo

      if (this.windows.speciesInfo) {
        this.bringToFront('speciesInfo')
      }
    },

    toggleTopSpeciesWindow (name) {
      const windowNames = {
        birdweather: 'birdweatherTopSpecies',
        birdnet: 'birdnetTopSpecies'
      }

      const key = windowNames[name]

      // Toggle specified window
      this.windows[key] = !this.windows[key]

      if (this.windows[key]) {
        // Close other windows
        for (const k in windowNames) {
          if (k !== name) {
            this.windows[windowNames[k]] = false
          }
        }

        this.hideTopBar()
      }
    },

    toggleStationType (type) {
      this.layers.birdweather.types[type] = !this.layers.birdweather.types[type]
    },

    toggleTopbar () {
      this.$refs.topbar.toggle()
    },

    hideTopBar () {
      this.$refs.topbar.hide()
    },

    zIndex (window) {
      return this.stackingOrder.indexOf(window)
    },

    bringToFront (window) {
      this.stackingOrder = [
        ...this.stackingOrder.filter((w) => w !== window),
        window
      ]
    },

    navigate (updatePath) {
      const stationPath = window.location.pathname.match(/\/stations\/(\d+)/)

      if (stationPath && this.stations.nodes) {
        const id = stationPath[1]
        this.selectStationById(id, updatePath)
      } else if (window.location.pathname === '/') {
        this.deselectStation(updatePath)
      }
    },

    enableWeatherLayer (layer) {
      this.layers.weather.layer = layer
      this.layers.weather.enabled = true
    }
  },

  computed: {
    ...mapState(['locale']),

    queryVariables () {
      const variables = {
        locale: this.locale,
        period: this.normalizedPeriod,
        center: this.bounds ? this.convertPoint(this.bounds.getCenter()) : [],
        stationTypes: this.normalizedStationTypes
      }

      if (this.selectedStation) {
        return {
          ...variables,
          stationIds: [this.selectedStation.id]
        }
      } else {
        return {
          ...variables,
          ...this.normalizeBounds(this.bounds)
        }
      }
    },

    normalizedPeriod () {
      const period = this.period

      return {
        count: period.count,
        unit:  period.unit,
        from:  period.from,
        to:    period.to
      }
    },

    selectedStationVideoId () {
      if (this.selectedStation) {
        return this.$youtube.getIdFromUrl(this.selectedStation.videoUrl)
      }
    },

    visibleStationsDescription () {
      if (this.$apollo.queries.counts.loading) {
        return 'Loading stations...'
      } else {
        return `${formatCount(this.counts.stations, 'station')} visible.`
      }
    },

    birdNETLayerDescription () {
      if (this.selectedSpecies) {
        if (this.layers.birdnet.loading) {
          return 'Loading data...'
        } else if (this.layers.birdnet.enabled) {
          return `${formatCount(this.layers.birdnet.count, 'sighting')}.`
        } else {
          return 'Data not loaded.'
        }
      } else {
        return 'No species selected.'
      }
    },

    eBirdLayerDescription () {
      if (this.selectedSpecies) {
        if (this.layers.ebird.loading) {
          return 'Loading data...'
        } else if (this.layers.ebird.enabled) {
          return `${formatCount(this.layers.ebird.count, 'observation')}.`
        } else {
          return 'Data not loaded.'
        }
      } else {
        return 'No species selected.'
      }
    },

    rangeLayerDescription () {
      if (this.selectedSpecies) {
        if (this.layers.range.loading) {
          return 'Loading range...'
        } else {
          if (this.layers.range.enabled) {
            if (this.layers.range.hasData) {
              return 'Range data loaded.'
            } else {
              return 'No range data available.'
            }
          } else {
            return 'Layer disabled.'
          }
        }
      } else {
        return 'No species selected.'
      }
    },

    weatherLayerDescription () {
      if (this.layers.weather.enabled) {
        return WEATHER_LAYERS[this.layers.weather.layer]
      } else {
        return 'Layer disabled.'
      }
    },

    // solarEclipseLayerDescription () {
    //   const now = this.currentTime.toISOString()
    //   if (now >= '2024-04-08T16:39:00.000Z' && now <= '2024-04-08T19:55:00.000Z') {
    //     return '🔴 Live Now!'
    //   } else {
    //     return 'April 8, 2024'
    //   }
    // },

    sightingMarkerRadius () {
      return 0.5 * this.zoom + 1.75
    },

    periodPickerOpensDirection () {
      if (window.outerWidth >= 1500) {
        return 'left'
      } else if (window.outerWidth >= 576) {
        return 'center'
      } else {
        return 'right'
      }
    },

    stationTypes () {
      return ['birdnetpi', 'puc', 'stream_youtube', 'stream_audio', 'app']
    },

    activeStationTypes () {
      return this.stationTypes.filter(type => this.layers.birdweather.types[type])
    },

    normalizedStationTypes () {
      return this.activeStationTypes.map(type => {
        return type === 'app' ? ['user', 'guest'] : type
      }).flat()
    },

    activeDetectionWithStation () {
      if (!this.activeDetection) {
        return
      }

      return {
        ...this.activeDetection,

        station: {
          ...this.activeDetection.station,
          ...this.selectedStation
        }
      }
    },

    weatherLayers () {
      return WEATHER_LAYERS
    },

    pageTitleBase () {
      const el = document.querySelector('title')
      return el.dataset.base
    },

    stationFocusArea () {
      if (this.layers.eclipse.enabled && this.$refs.eclipse) {
        return this.$refs.eclipse.path
      }
    }
  },

  watch: {
    bounds () {
      if (!this.selectedStation) return

      const coords = this.stationCoords(this.selectedStation)
      if (this.$refs.map.getCenter().distanceTo(coords) > 500) {
        this.selectedStationIsCentered = false
      }
    },

    activeDetection (detection) {
      if (detection) {
        window.history.pushState(null, null, `/detections/${detection.id}`)
      } else {
        window.history.pushState(null, null, '/')
      }
    },

    activeSoundscape (soundscape) {
      if (soundscape) {
        window.history.pushState(null, null, `/soundscapes/${soundscape.id}`)
      } else {
        window.history.pushState(null, null, '/')
      }
    },

    selectedSpecies (species) {
      if (species) {
        this.frameColor = species.color
      }
    }
  },

  apollo: {
    counts: {
      query () {
        if (this.showCounts) {
          return CountsQuery
        } else {
          return CountsMinimalQuery
        }
      },

      variables () {
        const variables = { ...this.queryVariables }

        if (this.selectedSpecies) {
          variables.speciesId = this.selectedSpecies.id
        }

        return variables
      }
    }
  },

  components: {
    AudioStream,
    BirdNetMapLayer,
    BirdWeatherMapLayer,
    DataLayer,
    DetectionModal,
    DetectionNotifications,
    EbirdMapLayer,
    ImportStatus,
    Map,
    MapLayer,
    MapWindow,
    MapWindowPane,
    NavBar,
    NavItem,
    PeriodPicker,
    RangeMapLayer,
    SearchBar,
    Settings,
    // SolarEclipseMapLayer,
    SoundscapeModal,
    SpeciesMapLayer,
    SpeciesThumbnail,
    SpeciesWindow,
    StationInfo,
    StationStats,
    Toggler,
    TopBirdNetSpeciesWindow,
    TopBirdWeatherSpeciesWindow,
    WeatherMapLayer
  }
}
</script>
